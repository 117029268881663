import json2csv from 'json2csv'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import store from '@/store'
import storeModule from '@/views/admin/transactions-v2/transactionsStoreModule'
import useTransactions from '@/views/admin/transactions-v2/useTransactions'
import { permissionSubjects } from '@/libs/acl/constants'

export const transactionsMixin = {
  mixins: [downloadExportFile],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-transactions'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    const {
      cancelCharge,
      deleteCharge,
      undoCancelCharge,
      updateChargeNotes,
      getTransaction,
      updateTransaction,
      retryTransaction,
      refundTransaction,
      voidTransaction,
      deletePayCondition,
      deleteTransaction,
      fetchPaymentMethods,
      fetchTransactionStatuses,
      refTransactionsTable,
      bulkDeleteTransactions,
      infoModalName,
      exportFamilyReport,
      exportTransactionsCSV,
    } = useTransactions()

    return {
      cancelCharge,
      deleteCharge,
      undoCancelCharge,
      updateChargeNotes,
      getTransaction,
      updateTransaction,
      retryTransaction,
      refundTransaction,
      voidTransaction,
      deletePayCondition,
      deleteTransaction,
      fetchPaymentMethods,
      fetchTransactionStatuses,
      refTransactionsTable,
      bulkDeleteTransactions,
      infoModalName,
      exportFamilyReport,
      exportTransactionsCSV,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
      currentRouteName: 'app-transactions/getCurrentRouteName',
      isProgramTransactions: 'app-transactions/getIsProgramTransactions',
      isAllTransactions: 'app-transactions/getIsAllTransactions',
      isApplicationTransactions: 'app-transactions/getIsApplicationTransactions',
      isParentTransactions: 'app-transactions/getIsParentTransactions',
      applicationId: 'app-transactions/getApplicationId',
      userId: 'app-application-edit/getUserId',
      cardId: 'app-application-edit/getCardId',
      userInfo: 'app-application-edit/getUserInfo',
      tableColumns: 'app-transactions/getTableColumns',
      visibleTableColumns: 'app-transactions/getVisibleTableColumns',
      selectedFiltersParams: 'app-transactions/getFiltersParams',
      totalItems: 'app-transactions/getTotalItems',
      perPageOptions: 'app-transactions/getPerPageOptions',
      dataMeta: 'app-transactions/getDataMeta',
      popupType: 'app-transactions/getPopupType',
      paymentPopupTitle: 'app-transactions/getPaymentPopupTitle',
      paymentPopupBtnTitle: 'app-transactions/getPaymentPopupBtnTitle',
      resumePaymentItem: 'app-transactions/getResumePaymentItem',
    }),
    perPage: {
      get() {
        return store.getters['app-transactions/getPerPage']
      },
      set(val) {
        store.commit('app-transactions/SET_PER_PAGE', val)
      },
    },
    currentPage: {
      get() {
        return store.getters['app-transactions/getCurrentPage']
      },
      set(val) {
        store.commit('app-transactions/SET_CURRENT_PAGE', val)
      },
    },
    searchQuery: {
      get() {
        return store.getters['app-transactions/getSearchQuery']
      },
      set(val) {
        store.commit('app-transactions/SET_SEARCH_QUERY', val)
      },
    },
    filterStatusOptions: {
      get() {
        return store.getters['app-transactions/getFilterStatusOptions']
      },
      set(val) {
        store.commit('app-transactions/SET_FILTER_STATUS_OPTIONS', val)
      },
    },
    filterPaymentMethod: {
      get() {
        return store.getters['app-transactions/getFilterPaymentMethod']
      },
      set(val) {
        store.commit('app-transactions/SET_FILTER_PAYMENT_METHOD', val)
      },
    },
    filterStatus: {
      get() {
        return store.getters['app-transactions/getFilterStatus']
      },
      set(val) {
        store.commit('app-transactions/SET_FILTER_STATUS', val)
      },
    },
    filterDate: {
      get() {
        return store.getters['app-transactions/getFilterDate']
      },
      set(val) {
        store.commit('app-transactions/SET_FILTER_DATE', val)
      },
    },
    sortBy: {
      get() {
        return store.getters['app-transactions/getSortBy']
      },
      set(val) {
        store.commit('app-transactions/SET_SORT_BY', val)
      },
    },
    isSortDirDesc: {
      get() {
        return store.getters['app-transactions/getIsSortDirDesc']
      },
      set(val) {
        store.commit('app-transactions/SET_IS_SORT_DIR_DESC', val)
      },
    },
    tableViewMode: {
      get() {
        return store.getters['app-transactions/getTableViewMode']
      },
      set(val) {
        store.commit('app-transactions/SET_TABLE_VIEW_MODE', val)
      },
    },
    filterPaymentMethodOptions: {
      get() {
        return store.getters['app-transactions/getFilterPaymentMethodOptions']
      },
      set(val) {
        store.commit('app-transactions/SET_FILTER_PAYMENT_METHOD_OPTIONS', val)
      },
    },
    selectedTransaction: {
      get() {
        return store.getters['app-transactions/getSelectedTransaction']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_TRANSACTION', val)
      },
    },
    transactionToRefund: {
      get() {
        return store.getters['app-transactions/getTransactionToRefund']
      },
      set(val) {
        store.commit('app-transactions/SET_TRANSACTION_TO_REFUND', val)
      },
    },
    selectedApplicationId: {
      get() {
        return store.getters['app-transactions/getSelectedApplicationId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_APPLICATION_ID', val)
      },
    },
    selectedUserId: {
      get() {
        return store.getters['app-transactions/getSelectedUserId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_USER_ID', val)
      },
    },
    selectedCardId: {
      get() {
        return store.getters['app-transactions/getSelectedCardId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_CARD_ID', val)
      },
    },
    selectedChargeId: {
      get() {
        return store.getters['app-transactions/getSelectedChargeId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_CHARGE_ID', val)
      },
    },
    selectedTranasctionId: {
      get() {
        return store.getters['app-transactions/getSelectedTransactionId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_TRANSACTION_ID', val)
      },
    },
    selectedRefundChargeId: {
      get() {
        return store.getters['app-transactions/getSelectedRefundChargeId']
      },
      set(val) {
        store.commit('app-transactions/SET_SELECTED_REFUND_CHARGE_ID', val)
      },
    },
    actionType: {
      get() {
        return store.getters['app-transactions/getActionType']
      },
      set(val) {
        store.commit('app-transactions/SET_ACTION_TYPE', val)
      },
    },
    confirmationModalToggle: {
      get() {
        return store.getters['app-transactions/getConfirmationModalToggle']
      },
      set(val) {
        store.commit('app-transactions/SET_CONFIRMATION_MODAL_TOGGLE', val)
      },
    },
    confirmationDeleteChargeModalToggle: {
      get() {
        return store.getters['app-transactions/getConfirmationDeleteChargeModalToggle']
      },
      set(val) {
        store.commit('app-transactions/SET_CONFIRMATION_DELETE_CHARGE_MODAL_TOGGLE', val)
      },
    },
    confirmationBulkDeleteTransactionModalToggle: {
      get() {
        return store.getters['app-transactions/getConfirmationBulkDeleteTransactionModalToggle']
      },
      set(val) {
        store.commit('app-transactions/SET_CONFIRMATION_BULK_DELETE_TRANSACTIONS_MODAL_TOGGLE', val)
      },
    },
    isBulkDeleteLoading: {
      get() {
        return store.getters['app-transactions/getIsBulkDeleteLoading']
      },
      set(val) {
        store.commit('app-transactions/SET_IS_BULK_DELETE_LOADING', val)
      },
    },
    holdPaymentItem: {
      get() {
        return store.getters['app-transactions/getHoldPaymentItem']
      },
      set(val) {
        store.commit('app-transactions/SET_HOLD_PAYMENT_ITEM', val)
      },
    },
    resumePaymentItem: {
      get() {
        return store.getters['app-transactions/getResumePaymentItem']
      },
      set(val) {
        store.commit('app-transactions/SET_RESUME_PAYMENT_ITEM', val)
      },
    },
    deletableTransactionId: {
      get() {
        return store.getters['app-transactions/getDeletableTransactionId']
      },
      set(val) {
        store.commit('app-transactions/SET_DELETABLE_TRANSACTION_ID', val)
      },
    },
    deletableChargeId: {
      get() {
        return store.getters['app-transactions/getDeletableChargeId']
      },
      set(val) {
        store.commit('app-transactions/SET_DELETABLE_CHARGE_ID', val)
      },
    },
    markedElements: {
      get() {
        return store.getters['app-transactions/getMarkedElements']
      },
      set(val) {
        store.commit('app-transactions/SET_MARKED_ELEMENTS', val)
      },
    },
    elementsPerPageCheckbox: {
      get() {
        return store.getters['app-transactions/getElementsPerPageCheckbox']
      },
      set(val) {
        store.commit('app-transactions/SET_ELEMENTS_PER_PAGE_CHECKBOX', val)
      },
    },
    isChargeDeleting: {
      get() {
        return store.getters['app-transactions/getIsChargeDeleting']
      },
      set(val) {
        store.commit('app-transactions/SET_IS_CHARGE_DELETING', val)
      },
    },
    canAddCharge() {
      return this.$can('create', permissionSubjects.Charge)
    },
    canCancelCharge() {
      return this.$can('cancel', permissionSubjects.Charge)
    },
    canUpdateCharge() {
      return this.$can('update', permissionSubjects.Charge)
    },
    canDeleteCharge() {
      return this.$can('delete', permissionSubjects.Charge)
    },
    canCreatePayment() {
      return this.$can('create', permissionSubjects.Payment)
    },
    canRefundPayment() {
      return this.$can('refund', permissionSubjects.Payment)
    },
    canEditPayment() {
      return this.$can('edit', permissionSubjects.Payment)
    },
    showAddChargeButton() {
      return this.canAddCharge && !this.isAllTransactions && !this.isParentTransactions
    },
    showMakePaymentButton() {
      return this.canCreatePayment && !this.isAllTransactions && !this.isParentTransactions
    },
    showAddRefundButton() {
      return this.canRefundPayment && !this.isAllTransactions && !this.isParentTransactions
    },
    bulkDeleteTransactionsMessage() {
      if (this.markedElements.length) {
        const creditCardTransactions = this.markedElements.filter(transactionId => {
          const transaction = this.$refs.refTransactionsTable.localItems.find(el => el.id === transactionId)
          return this.isPayableType(transaction.type)
        })
        return creditCardTransactions.length
          ? `Are you sure you want to delete ${this.markedElements.length} transactions? Some of them(${creditCardTransactions.length} transaction(s)) have a "Credit Card" payment method and can't be deleted`
          : `Are you sure you want to delete ${this.markedElements.length} transactions?`
      }
      return ''
    },
    isAllPending() {
      if (this.markedElements.length) {
        const isPendingTransactions = this.markedElements.every(transactionId => {
          const transaction = this.$refs.refTransactionsTable.localItems.find(el => el.id === transactionId)
          return transaction.status === 'Pending'
        })
        return isPendingTransactions
      }
      return true
    },
  },
  methods: {
    isPayableType(type) {
      return ['credit card', 'ach'].includes(type.toLowerCase())
    },
    async fetchTransactionsList() {
      return this.$store.dispatch('app-transactions/fetchTransactionsList')
    },
    updateTable() {
      this.transactionToRefund = null
      if (this.isApplicationTransactions) {
        this.$store.dispatch('app-application-edit/fetchApplication')
      }
      this.$refs.refTransactionsTable.refresh()
    },
    resolveStatusVariant(status) {
      if (status.toLowerCase() === 'completed') return 'success'
      if (status.toLowerCase() === 'payment failed') return 'danger'
      if (status.toLowerCase() === 'pending') return 'warning'
      if (status.toLowerCase() === 'canceled') return 'pink'
      if (status.toLowerCase() === 'refunded') return 'primary'
      if (status.toLowerCase() === 'scheduled') return 'secondary'
      if (status.toLowerCase() === 'paused') return 'warning'
      return 'primary'
    },
    showEditNotesModal(row) {
      this.selectedTransaction = row
      this.$nextTick(() => {
        this.$bvModal.show('edit-notes-modal')
      })
    },
    getApplicationUserType(user) {
      return user.role.display_name === 'Staff' ? user.role.display_name : this.camperStudent(1)
    },
    updatePerPage(val) {
      localStorage.setItem('transactionsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      this.$store.commit('app-transactions/SET_FILTER_PARAMS')
    },
    async transactionRetry(id) {
      await this.retryTransaction(id).then(() => {
        if (this.$refs.refTransactionsTable) {
          this.updateTable()
        } else {
          this.$emit('updateTable')
        }
      })
    },
    async setPaymentMethodsList() {
      this.filterPaymentMethodOptions = await this.fetchPaymentMethods()
    },
    async setTransactionsStatusesList() {
      this.filterStatusOptions = await this.fetchTransactionStatuses()
    },
    checkIsPaymentEditable(item) {
      if (item.status === 'Processing') {
        return false
      }
      if (item.model_type === 'Payment' && this.canEditPayment) {
        if (item.status.toLowerCase() === 'refund to cash bank') {
          return false
        }
        if (item.is_manual_upcoming_payment && item.app_pay_condition_transaction) {
          return !(this.isPayableType(item.type) && item.status === 'Completed')
        }
        if (!['Charge'].includes(item.type)) {
          return true
        }
      }
      return false
    },
    checkIsPaymentRefundable(item) {
      if (
        item.model_type === 'Payment'
        && ['completed', 'partial refunded', 'added to wallet'].includes(item.status.toLowerCase())
        && item.type.toLowerCase() !== 'Refund'
        && item.refundable_sum > 0
        && this.canRefundPayment
      ) {
        return true
      }
      return false
    },
    handleExportView() {
      const currentItems = []
      const displayedFields = this.$refs.refTransactionsTable.computedFields

      this.$refs.refTransactionsTable.localItems.forEach(item => {
        const currentItem = {}

        displayedFields.forEach(field => {
          if (field.key in item) {
            currentItem[field.label] = item[field.key]
          }
          if (field.key === 'payment_date') {
            currentItem[field.label] = this.$options.filters.longDateTime(this.getPayDate(item))
          }
          if (field.key === 'payer_name') {
            currentItem[field.label] = item.user.full_name
          }
          if (field.key === 'charge_balance') {
            currentItem[field.label] = item.charge_id ? item.charge_balance : ''
          }
        })

        currentItems.push(currentItem)
      })

      const csvData = json2csv.parse(currentItems)

      this.downloadFile(csvData, `Transactions - ${moment().format('YYYY-MM-DD')}`, 'csv')
    },
    handleAddCharge() {
      if (this.isApplicationTransactions) {
        this.selectedApplicationId = this.applicationId
        this.$nextTick(() => {
          this.$bvModal.show('adding-charge-modal')
        })
      } else {
        this.selectStudenForAddingCharge()
      }
    },
    handleAddPayment() {
      if (this.isApplicationTransactions) {
        this.selectedUserId = this.userId
        this.selectedApplicationId = this.applicationId
        this.selectedCardId = this.cardId
        this.showAddPaymentModal()
      } else {
        this.selectStudenForMakePayment()
      }
    },
    handleAddRefund() {
      if (this.isApplicationTransactions) {
        this.selectedApplicationId = this.applicationId
        this.$nextTick(() => {
          this.$bvModal.show('adding-refund-modal')
        })
      } else {
        this.selectStudenForAddingRefund()
      }
    },
    selectStudenForAddingCharge() {
      this.actionType = 'Add Charge'
      this.$bvModal.show('application-selection-modal')
    },
    selectStudenForAddingRefund() {
      this.actionType = 'Add Refund'
      this.$bvModal.show('application-selection-modal')
    },
    selectStudenForMakePayment() {
      this.actionType = 'Make Payment'
      this.$bvModal.show('application-selection-modal')
    },
    selectApplication(data) {
      this.selectedApplicationId = data.applicationId
      this.selectedUserId = data.userId
      this.$nextTick(() => {
        this.openActionModal()
      })
    },
    openActionModal() {
      if (this.actionType === 'Add Charge') this.$bvModal.show('adding-charge-modal')
      if (this.actionType === 'Add Refund') this.$bvModal.show('adding-refund-modal')
      if (this.actionType === 'Make Payment') this.showAddPaymentModal()
    },
    saveVisibleColumns(columns) {
      this.$store.commit('app-transactions/SET_TABLE_COLUMNS', JSON.parse(JSON.stringify(columns)))
    },
    isAvailableToPause(item) {
      return !['paused', 'completed', 'refunded'].includes(item.status.toLowerCase())
        && this.isPayableType(item.type)
        && item.app_pay_condition_transaction
    },
    isAvailableToResume(item) {
      return item.status.toLowerCase() === 'paused'
        && this.isPayableType(item.type)
        && item.app_pay_condition_transaction
    },
    getPayDate(item) {
      return item.pay_date
    },
    showEditPaymentModal(item) {
      const options = {
        selectedUserId: item.user.id,
        selectedApplicationId: item.application_id,
        selectedTransaction: item,
        popupType: 'edit-pct',
        paymentPopupTitle: 'Edit Payment',
        paymentPopupBtnTitle: 'Save',
      }
      store.commit('app-transactions/SET_PAYMENT_POPUP_OPTIONS', options)

      this.$nextTick(() => {
        this.$bvModal.show('edit-pct-payment')
      })
    },
    showAddPaymentModal() {
      const options = {
        selectedUserId: this.selectedUserId,
        selectedApplicationId: this.selectedApplicationId,
        selectedCardId: this.selectedCardId,
        selectedTransaction: null,
        popupType: 'add',
        paymentPopupTitle: 'Make Payment',
        paymentPopupBtnTitle: 'Submit Payment',
      }
      store.commit('app-transactions/SET_PAYMENT_POPUP_OPTIONS', options)

      this.$nextTick(() => {
        this.$bvModal.show('add-payment')
      })
    },
    openDeleteConfirmation(item) {
      this.deletableTransactionId = item.id
      this.$bvModal.show('confirmation-delete-payment')
    },
    openDeleteChargeConfirmation(item) {
      this.deletableChargeId = item.charge_id
      this.$bvModal.show('delete-charge-modal')
    },
    totalAmountCharges(charges) {
      return charges.reduce((acc, curItem) => acc + Number(curItem.amount), 0)
    },
    totalPaidCharges(charges) {
      return charges.reduce((acc, curItem) => {
        if (['Manual Cancel', 'Canceled'].includes(curItem.status)) {
          return acc
        }
        return acc + Number(curItem.paid_sum)
      }, 0)
    },
    totalAmountTransactions(transactions) {
      return transactions.reduce((acc, curItem) => acc + Number(curItem.amount), 0)
    },
    totalPaidTransactions(transactions) {
      return transactions.reduce((acc, curItem) => acc + Number(curItem.paid_sum), 0)
    },
    showCancelChargeModal(chargeId) {
      this.selectedChargeId = chargeId
      this.$bvModal.show('cancel-charge')
    },
    showCancelACHModal(transaction) {
      this.selectedTransaction = transaction
      this.$bvModal.show('cancel-ach')
    },
    async chargeCancel(queryParams) {
      const id = this.selectedChargeId
      await this.cancelCharge({ id, queryParams }).then(() => {
        if (this.$refs.refTransactionsTable) {
          this.updateTable()
        } else {
          this.$emit('updateTable')
        }
      })
    },
    async achCancel(queryParams) {
      if (this.selectedTransaction) {
        await this.refundTransaction(this.selectedTransaction.id, {
          notes: queryParams.reason,
        }).then(() => {
          if (this.$refs.refTransactionsTable) {
            this.updateTable()
          } else {
            this.$emit('updateTable')
          }
        })
      }
    },
    async undoChargeCancel(id) {
      await this.undoCancelCharge(id).then(() => {
        if (this.$refs.refTransactionsTable) {
          this.updateTable()
        } else {
          this.$emit('updateTable')
        }
      })
    },
    showPaymentRefundModal(transaction) {
      store.commit('app-transactions/SET_TRANSACTION_TO_REFUND', transaction)

      this.$nextTick(() => {
        this.$bvModal.show('payment-refund-modal')
      })
    },
    async acceptRefund(refundData) {
      await this.refundTransaction(this.transactionToRefund.id, refundData).then(() => {
        if (this.$refs.refTransactionsTable) {
          this.updateTable()
        } else {
          this.$emit('updateTable')
        }
      })
    },
    checkIsPaymentDeletable(item) {
      if (item.type === 'Refund' && item.paidFromTransactions.length === 0) {
        return true
      }
      if (item.model_type === 'Payment') {
        if (item.is_manual_upcoming_payment && item.app_pay_condition_transaction) {
          return !(this.isPayableType(item.type) && item.status === 'Completed')
        }
        if (!this.isPayableType(item.type)) {
          return true
        }
        if (this.isPayableType(item.type) && item.amount === 0) {
          return true
        }
      }
      return false
    },
    checkIsChargeDeletable(item) {
      return item.charge_id && this.canDeleteCharge
    },
    async deleteApplicationPayment() {
      if (this.deletableTransactionId) {
        await this.deleteTransaction(this.deletableTransactionId).then(() => {
          this.$bvModal.hide('confirmation-delete-payment')
          if (this.$refs.refTransactionsTable) {
            this.updateTable()
          } else {
            this.$emit('updateTable')
          }
        })
        this.deletableTransactionId = null
      }
    },
    async deleteApplicationCharge(withoutAutoReturn) {
      if (this.deletableChargeId) {
        const queryParams = {
          without_auto_return: withoutAutoReturn,
        }
        this.isChargeDeleting = true

        await this.deleteCharge(this.deletableChargeId, queryParams)
          .then(() => {
            this.isChargeDeleting = false
            this.$bvModal.hide('delete-charge-modal')
            if (this.$refs.refTransactionsTable) {
              this.updateTable()
            } else {
              this.$emit('updateTable')
            }
          })
        this.deletableChargeId = null
      }
    },
    showHoldPayments(item) {
      if (item) {
        this.selectedApplicationId = item.application_id
        this.holdPaymentItem = item.app_pay_condition_transaction
      }
      this.$nextTick(() => {
        this.$bvModal.show('hold-payments')
      })
    },
    showResumePayments(item) {
      if (item) {
        this.selectedApplicationId = item.application_id
        this.resumePaymentItem = item.app_pay_condition_transaction
      }
      this.$nextTick(() => {
        this.$bvModal.show('resume-payments')
      })
    },
    clearFilters() {
      this.$store.commit('app-transactions/CLEAR_FILTERS')
    },
    showEditChargeModal(chargeId) {
      this.selectedChargeId = chargeId
      this.$nextTick(() => {
        this.$bvModal.show('edit-charge-modal')
      })
    },
    showEditRefundChargeModal(transactionId) {
      this.selectedRefundChargeId = transactionId
      this.$nextTick(() => {
        this.$bvModal.show('edit-refund-charge-modal')
      })
    },
    markElementsOnPage(val) {
      const elements = this.markedElements
      if (val) {
        this.refTransactionsTable.localItems.forEach(item => {
          const index = elements.indexOf(item.id)
          if (index < 0) {
            elements.push(item.id)
          }
        })
      } else {
        this.refTransactionsTable.localItems.forEach(item => {
          const index = elements.indexOf(item.id)
          if (index >= 0) {
            elements.splice(index, 1)
          }
        })
      }
      this.markedElements = elements
    },
    handleElementsPerPageCheckbox() {
      let counter = 0
      this.refTransactionsTable.localItems.forEach(item => {
        if (this.markedElements.indexOf(item.id) !== -1) {
          counter += 1
        }
      })

      this.elementsPerPageCheckbox = !!(this.refTransactionsTable.localItems && counter === this.refTransactionsTable.localItems.length)
    },
    bulkActionSuccess() {
      this.elementsPerPageCheckbox = false
      this.markedElements = []
      this.updateTable()
    },
    async bulkDelTransactions() {
      const queryParams = {
        transactions_ids: this.markedElements,
      }
      this.isBulkDeleteLoading = true
      await this.bulkDeleteTransactions(queryParams)
        .then(() => {
          this.isBulkDeleteLoading = false
          this.confirmationBulkDeleteTransactionModalToggle = false
          this.bulkActionSuccess()
        })
    },
    showBulkChangeStatusModal() {
      if (this.isAllPending) {
        this.$bvModal.show('bulk-change-transactions-status')
      } else {
        this.$bvModal.show(`info${this.infoModalName}`)
      }
    },
    async exportFamilyReportToPdf() {
      await this.exportFamilyReport(this.applicationId)
        .then(response => {
          if (response.status === 200) {
            this.downloadFile(response.data, `${this.userInfo.full_name}(Statement)`, 'pdf')
          }
        })
    },
    async exportTransactions(configs) {
      if (!configs.program_id && this.programId) {
        // eslint-disable-next-line no-param-reassign
        configs.program_id = this.programId
      }
      if (!configs.application_id && this.applicationId) {
        // eslint-disable-next-line no-param-reassign
        configs.application_id = this.applicationId
      }

      if (configs.userName === '') {
        this.$delete(configs, 'userName')
      }

      if (configs.status === null) {
        this.$delete(configs, 'status')
      }

      if (configs.createdAt === null) {
        this.$delete(configs, 'createdAt')
      }

      await this.exportTransactionsCSV(configs)
        .then(response => {
          if (response.status === 200 && configs.isSendByEmail === false) {
            this.downloadFile(response.data, `${this.programId}(Program Transactions Export)`, 'csv')
          }
        }).finally(() => {
          this.$bvModal.hide('export-transactions-modal')
        })
    },
  },
}

export const _ = null
