<template>
  <div
    class="mb-2"
  >
    <b-card
      v-if="isStripe"
      class="pt-1"
      bg-variant="light"
      border-variant="secondary"
    >
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Credit Card Number"
          >
            <div
              :id="'card-number-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Expiry Date"
          >
            <div
              :id="'card-expiry-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="CVC/CVV"
          >
            <div
              :id="'card-cvc-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="hasCardError"
        :id="'payment-message-' + stripeItemID"
        class="form-group pt-1"
      >
        <p
          v-if="errorNumber"
          class="text-center text-danger"
        >
          {{ errorNumber }}
        </p>
        <p
          v-if="errorCvc"
          class="text-center text-danger"
        >
          {{ errorCvc }}
        </p>
        <p
          v-if="errorExpiry"
          class="text-center text-danger"
        >
          {{ errorExpiry }}
        </p>
      </div>
    </b-card>

    <b-card
      v-if="isCardknox"
      class="pt-1"
      bg-variant="light"
      border-variant="secondary"
    >
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Credit Card Number"
          >
            <ifields
              ref="ifieldCardRef"
              :type="CARD_TYPE"
              :account="cardknoxIFieldsOptions"
              :options="{
                autoFormat: true,
                autoFormatSeparator: ' ',
                autoSubmit: false,
                placeholder: '1234 1234 1234 1234',
                iFieldstyle: {
                  fontSize: '1rem',
                  fontWeight: '400',
                  fontFamily: 'Montserrat, Helvetica, Arial, serif',
                  backgroundColor: '#fff',
                  color: '#6e6b7b',
                  border: 'none',
                  outline: 'none',
                }
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Expiry Date"
          >
            <b-form-input
              v-model="cardExpiry"
              v-mask="'##/##'"
              class="stripe_row cardknox-expiry"
              type="text"
              name="exp-date"
              inputmode="numeric"
              maxlength="5"
              placeholder="MM / YY"
              :masked="true"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-card>

    <b-row>
      <b-col
        sm="6"
      >
        <b-button
          variant="outline-primary"
          class="w-100"
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col
        sm="6"
      >
        <b-button
          variant="primary"
          class="w-100"
          @click="saveCard"
        >
          Save Card
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'
import ifields, { CARD_TYPE } from '@cardknox/vue-cardknox-ifields'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,

    ifields,
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      errorNumber: null,
      errorExpiry: null,
      errorCvc: null,
      publishableKey: null,
      stripeItemID: '',
      isStripe: false,
      isCardknox: false,
      cardknoxIFieldsOptions: {},
      CARD_TYPE,
    }
  },
  computed: {
    hasCardError() {
      return !!this.errorNumber || !!this.errorExpiry || !!this.errorCvc
    },
  },
  beforeMount() {
    this.schoolData = JSON.parse(localStorage.getItem('schoolData'))
    this.isStripe = this.schoolData.config.provider === 'stripe'
    this.isCardknox = this.schoolData.config.provider === 'cardknox'

    if (this.isStripe) {
      this.publishableKey = this.schoolData.config.public_stripe_key
      this.stripeItemID = 1
    }

    if (this.isCardknox) {
      this.cardknoxIFieldsOptions = {
        xKey: this.schoolData.config.cardknox_ifields_key,
        xSoftwareVersion: '1.0.0',
        xSoftwareName: 'Bolder',
      }
    }
  },
  async mounted() {
    if (this.isStripe) {
      await this.prepareStripe()
    }
  },
  beforeDestroy() {
    if (this.isStripe) {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    }
  },
  methods: {
    async prepareStripe() {
      // Load Stripe.js with your publishable key
      this.stripe = await loadStripe(this.publishableKey)
      const elements = this.stripe.elements()

      const style = {
        style: {
          base: {
            fontSize: '1rem',
            color: '#6e6b7b',
            fontWeight: '400',
            fontFamily: 'Montserrat, Helvetica, Arial, serif',
            backgroundColor: '#fff',
            '::placeholder': {
              color: '#ceced3',
              fontWeight: '400',
              fontSize: '1rem',
              fontFamily: 'Montserrat, Helvetica, Arial, serif',
            },
          },
        },
      }

      // Create and mount the card number input field
      this.cardNumber = elements.create('cardNumber', style)
      this.cardNumber.mount(`#card-number-${this.stripeItemID}`)
      this.cardNumber.addEventListener('change', event => {
        if (event.error) {
          this.errorNumber = event.error.message
        } else {
          this.errorNumber = null
        }
      })

      this.cardExpiry = elements.create('cardExpiry', style)
      this.cardExpiry.mount(`#card-expiry-${this.stripeItemID}`)
      this.cardExpiry.addEventListener('change', event => {
        if (event.error) {
          this.errorExpiry = event.error.message
        } else {
          this.errorExpiry = null
        }
      })

      this.cardCvc = elements.create('cardCvc', style)
      this.cardCvc.mount(`#card-cvc-${this.stripeItemID}`)
      this.cardCvc.addEventListener('change', event => {
        if (event.error) {
          this.errorCvc = event.error.message
        } else {
          this.errorCvc = null
        }
      })
    },
    resetCardData() {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    },
    cancel() {
      this.$emit('cancel')
      this.resetCardData()
    },
    async fetchStripeCardToken() {
      const { token, error } = await this.stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById(`#${this.stripeItemID}card-error`).innerHTML = error.message
        return null
      }

      return token
    },
    async saveCard() {
      if (this.hasCardError) {
        return
      }

      if (this.isCardknox) {
        await this.handleCardknoxCCPayment()
      }
      if (this.isStripe) {
        await this.handleStripePayment()
      }
    },
    async handleStripePayment() {
      const token = await this.fetchStripeCardToken()

      token.gateway = 'stripe'

      this.$emit('addNewCard', token)
    },
    async fetchCardknoxCardToken() {
      return new Promise(resolve => {
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.ifieldCardRef._onToken = response => {
          this.cardToken = response?.data?.xToken || ''
          resolve(this.cardToken)
        }
        this.$refs.ifieldCardRef.getToken()
      })
    },
    async handleCardknoxCCPayment() {
      if (!this.$refs.ifieldCardRef) {
        return
      }

      await this.fetchCardknoxCardToken()

      if (!this.cardToken || !this.cardExpiry) return

      try {
        const cardNumber = this.cardToken.split(';')[0]
        const match = this.cardToken.match(/cc_(.*?)_keyed/)
        const cardType = match ? match[1] : 'unknown'

        const [month, year] = this.cardExpiry.split('/')

        this.$emit('addNewCard', {
          id: this.cardToken,
          card: {
            exp_month: month,
            exp_year: year,
            last4: cardNumber.slice(-4),
            brand: cardType,
          },
          gateway: 'cardknox',
        })
      } catch (error) {
        // document.getElementById('#' + this.cardknoxItemID + 'card-error').innerHTML = error.message
        // console.error('Ошибка токенизации:', error)
      }
    },
  },
}
</script>

<style>
.stripe_row {
  padding: 0.6rem 1rem;
}

iframe {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  height: 2.714rem;
  width: 100%;
  padding: 0px
}
</style>

<!--//border: 0 solid transparent;-->
<!--//height: 60px;-->
<!--//padding: 0px;-->
<!--//margin-bottom: 5px;-->
<!--//-->
