<template>
  <div
    class="mb-2"
  >
    <b-card
      class="pt-1"
      bg-variant="light"
      border-variant="secondary"
    >
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Account Name"
          >
            <b-form-input
              v-model="achName"
              type="text"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Account Number"
          >
            <ifields
              ref="ifieldACHRef"
              class="form-control"
              :type="ACH_TYPE"
              :account="iFieldsOptions"
              :options="{
                autoFormat: true,
                autoFormatSeparator: ' ',
                autoSubmit: false,
                placeholder: '',
                iFieldstyle: {
                  fontSize: '1rem',
                  fontWeight: '400',
                  fontFamily: 'Montserrat, Helvetica, Arial, serif',
                  backgroundColor: '#fff',
                  color: '#6e6b7b',
                  border: 'none',
                  outline: 'none',
                }
              }"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Routing Number"
          >
            <b-form-input
              v-model="achRouting"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        sm="6"
      >
        <b-button
          variant="outline-primary"
          class="w-100"
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col
        sm="6"
      >
        <b-button
          variant="primary"
          class="w-100"
          @click="saveCard"
        >
          Save Card
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton, BFormInput,
} from 'bootstrap-vue'
import ifields, { ACH_TYPE } from '@cardknox/vue-cardknox-ifields'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,

    ifields,
  },
  data() {
    return {
      ACH_TYPE,
      achName: null,
      achRouting: null,
      iFieldsOptions: {
        xKey: null,
        xSoftwareVersion: '1.0.0',
        xSoftwareName: 'Bolder',
      },
    }
  },
  beforeMount() {
    this.schoolData = JSON.parse(localStorage.getItem('schoolData'))
    this.iFieldsOptions.xKey = this.schoolData.config.cardknox_ifields_key
  },
  methods: {
    resetCardData() {
      this.achName = null
      this.achRouting = null
      this.cardToken = null
    },
    cancel() {
      this.$emit('cancel')
      this.resetCardData()
    },
    async fetchCardknoxACHToken() {
      return new Promise(resolve => {
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.ifieldACHRef._onToken = response => {
          this.cardToken = response?.data?.xToken || ''
          resolve(this.cardToken)
        }
        this.$refs.ifieldACHRef.getToken()
      })
    },
    async saveCard() {
      if (!this.$refs.ifieldACHRef) {
        return
      }

      await this.fetchCardknoxACHToken()

      if (!this.cardToken) return

      try {
        this.$emit('addNewCard', {
          id: this.cardToken,
          type: 'ACH',
          gateway: 'cardknox',
          ach_routing: this.achRouting,
          ach_name: this.achName,
        })
      } catch (error) {
        // document.getElementById('#' + this.cardknoxItemID + 'card-error').innerHTML = error.message
        // console.error('Ошибка токенизации:', error)
      }
    },
  },
}
</script>

<style lang="scss">

</style>
